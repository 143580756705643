import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import { opacityStyle } from '~/styles/opacityStyle';

export const container = css``;

const height = 20;

export const messageBalloon = css`
  ${textCss({ size: 'm', weight: 'bold' })}

  position: relative;
  display: inline-block;
  box-sizing: content-box;
  color: ${colors.black};
  height: ${height}px;
  line-height: ${height}px;
  padding: ${layout.baseSize.margin}px ${layout.baseSize.margin * 3}px;
  border-radius: ${layout.baseSize.borderRadius}px;
  background-color: ${colors.main};
  margin-bottom: ${layout.baseSize.margin * 4}px;

  &::after {
    content: '';
    position: absolute;
    top: ${height + layout.baseSize.margin * 2}px;
    display: block;
    width: 0;
    height: 0;
    border-width: ${layout.baseSize.margin}px;
    border-style: solid;
    left: 50%;
    transform: translateX(-50%);
    border-color: ${colors.main} transparent transparent transparent;
  }
`;

export const imageContainer = css`
  ${opacityStyle.translucent}
`;
